import React from 'react';
import {
  Button,
  CloseButton,
  Tag,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import CustomTiers from 'components/scores/modal/CustomTiers';
import GuidelineWeights from 'components/scores/modal/GuidelineWeights';
import ScoreConfirmation from 'components/scores/modal/ScoreConfirmation';
import ScoreDetailInputs from 'components/scores/modal/ScoreDetailInputs';
import ScoreInfoNotice from 'components/scores/modal/ScoreInfoNotice';
import GuidelineTable from 'components/scores/table/GuidelineTable';
import { track } from 'utilities/mixpanel';
import { toSnakeCaseKeys } from 'utilities/object';
import { getAuthenticityToken } from 'utilities/requests';
import { scoresPath } from 'utilities/routes';
import styles from '../Steps.module.css';

async function onSubmit({
  description,
  label,
  name,
  onNext,
  qualityRate,
  qualitySpendRate,
  selectedGuidelines,
  setIsNextDisabled,
  showInDashboard,
  showInReporting,
  showInScorecard,
  tiers,
}) {
  setIsNextDisabled(true);

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('description', description);
  formData.append('label', label);
  formData.append('name', name);
  formData.append('quality_rate_name', qualityRate);
  formData.append('quality_spend_rate_name', qualitySpendRate);
  formData.append('show_in_dashboard', showInDashboard);
  formData.append('show_in_reporting', showInReporting);
  formData.append('show_in_scorecard', showInScorecard);

  const guidelineWeights = selectedGuidelines.map(({
    id,
    channelId,
    weight,
  }) => ({
    guideline_id: id,
    channel_id: channelId,
    weight,
  }));
  const snakeCaseTiers = tiers.map((tier) => toSnakeCaseKeys(tier));
  formData.append('guideline_weights', JSON.stringify(guidelineWeights));
  formData.append('tiers', JSON.stringify(snakeCaseTiers));

  const response = await fetch(scoresPath(), {
    method: 'POST',
    body: formData,
  });

  if (response.ok) {
    const { id: scoreId } = await response.json();
    track('new_score', {
      score_id: scoreId,
      score_label: label,
    });
    onNext();
  } else {
    addToast('Something went wrong, please try again.', {
      size: 'large',
      type: 'error',
    });
  }

  setIsNextDisabled(false);
}

function onConfirm() {
  window.location.href = scoresPath();
}

function getContent(step, props) {
  const {
    canEditVisibility,
    description,
    guidelines,
    handleTierChange,
    handleTierCountChange,
    label,
    name,
    qualityRate,
    qualitySpendRate,
    onGuidelineSelect,
    onGuidelineWeightChange,
    scoreDenyList,
    selectedGuidelines,
    setDescription,
    setIsNextDisabled,
    setLabel,
    setName,
    setQualityRate,
    setQualitySpendRate,
    setShowInDashboard,
    setShowInReporting,
    setShowInScorecard,
    showInDashboard,
    showInReporting,
    showInScorecard,
    tierCount,
    tiers,
  } = props;

  switch (step) {
    case 1:
      return (
        <ScoreDetailInputs
          canEditVisibility={canEditVisibility}
          description={description}
          label={label}
          name={name}
          qualityRate={qualityRate}
          qualitySpendRate={qualitySpendRate}
          scoreDenyList={scoreDenyList}
          setIsNextDisabled={setIsNextDisabled}
          showInDashboard={showInDashboard}
          showInReporting={showInReporting}
          showInScorecard={showInScorecard}
          onDescriptionChange={setDescription}
          onLabelChange={setLabel}
          onNameChange={setName}
          onQualityRateChange={setQualityRate}
          onQualitySpendRateChange={setQualitySpendRate}
          onShowInDashboardChange={setShowInDashboard}
          onShowInReportingChange={setShowInReporting}
          onShowInScorecardChange={setShowInScorecard}
        />
      );
    case 2:
      return (
        <GuidelineTable
          guidelines={guidelines}
          isSelectable
          onGuidelineSelect={onGuidelineSelect}
        />
      );
    case 3:
      return (
        <GuidelineWeights
          guidelines={selectedGuidelines}
          isEditable
          onWeightChange={onGuidelineWeightChange}
        />
      );
    case 4:
      return (
        <CustomTiers
          tierCount={tierCount}
          tiers={tiers}
          onTierChange={handleTierChange}
          onTierCountChange={handleTierCountChange}
        />
      );
    case 5:
      return (
        <ScoreConfirmation
          description={description}
          guidelines={selectedGuidelines}
          label={label}
          tiers={tiers}
        />
      );
    case 6:
      return <ScoreInfoNotice />;
    default:
      return null;
  }
}

function getFooter(step, props) {
  const {
    isNextDisabled,
    numSelectedGuidelines,
    onClose,
    onNext,
    onPrevious,
  } = props;

  switch (step) {
    case 1:
      return (
        <div className="buttonGroup">
          <Button
            label="Cancel"
            variant="tertiary"
            onClick={onClose}
          />
          <Button
            disabled={isNextDisabled}
            label="Continue"
            onClick={onNext}
          />
        </div>
      );
    case 2:
      return (
        <div className="buttonGroup">
          <Button
            label="Back"
            variant="tertiary"
            onClick={onPrevious}
          />
          <Button
            disabled={numSelectedGuidelines === 0}
            label="Continue"
            onClick={onNext}
          />
        </div>
      );
    case 3:
      return (
        <div className="buttonGroup">
          <Button
            label="Back"
            variant="tertiary"
            onClick={onPrevious}
          />
          <Button
            label="Continue"
            onClick={onNext}
          />
        </div>
      );
    case 4:
      return (
        <div className="buttonGroup">
          <Button
            label="Back"
            variant="tertiary"
            onClick={onPrevious}
          />
          <Button
            label="Continue"
            onClick={onNext}
          />
        </div>
      );
    case 5:
      return (
        <div className="buttonGroup">
          <Button
            label="Back"
            variant="tertiary"
            onClick={onPrevious}
          />
          <Button
            disabled={isNextDisabled}
            label="Confirm"
            onClick={() => onSubmit(props)}
          />
        </div>
      );
    case 6:
      return (
        <Button
          label="Got it!"
          onClick={onConfirm}
        />
      );
    default:
      return null;
  }
}

function getHeader(step, props) {
  const {
    numSelectedGuidelines,
    onClose,
  } = props;

  let title;
  if (step === 1) {
    title = 'Add New Score Details (1/5)';
  } else if (step === 5) {
    title = 'Confirm Score Details (5/5)';
  } else if (step === 6) {
    title = 'New Score Creation Successful!';
  }

  switch (step) {
    case 1:
    case 5:
      return (
        <div className={styles.header}>
          <h4>{ title }</h4>
          <CloseButton onClick={onClose} />
        </div>
      );
    case 2:
      return (
        <div className={styles.header}>
          <div className="u-flexRow u-alignCenter u-gap-16">
            <h4>Select Guidelines to Add to Custom Score (2/5)</h4>
            <Tag label={`${numSelectedGuidelines} Guidelines Selected`} />
          </div>
          <CloseButton onClick={onClose} />
        </div>
      );
    case 3:
      return (
        <div className={styles.headerAndSubtitle}>
          <div className={styles.header}>
            <h4>Configure Guideline Weights (3/5)</h4>
            <CloseButton onClick={onClose} />
          </div>
          <div className="t-subtitle">All guidelines are equally weighted as a default. Select reset to weigh all guidelines equally. Guideline weights can be edited in score settings after your new score has been published.</div>
        </div>
      );
    case 4:
      return (
        <div className={styles.headerAndSubtitle}>
          <div className={styles.header}>
            <h4>Configure Tier Details (4/5)</h4>
            <CloseButton onClick={onClose} />
          </div>
          <div className="t-subtitle">Tiers are score specific and will not effect any of your existing scores </div>
        </div>
      );
    case 6:
      return (
        <div className={styles.header}>
          <h4>{ title }</h4>
          <CloseButton onClick={onConfirm} />
        </div>
      );
    default:
      return null;
  }
}

function getModalSize(step) {
  if (step === 1) {
    return 'custom';
  } else if (step === 6) {
    return 'medium';
  }

  return 'full';
}

export function getStepContent(step, props) {
  return {
    content: getContent(step, props),
    footer: getFooter(step, props),
    header: getHeader(step, props),
    size: getModalSize(step),
  };
}
