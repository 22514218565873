import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  RadioGroup,
} from '@makeably/creativex-design-system';
import TierConfirmation from 'components/rules/tiers/TierConfirmation';
import TierForm from 'components/rules/tiers/TierForm';
import TierRangeChart from 'components/rules/tiers/TierRangeChart';
import { sortObjectArray } from 'utilities/array';
import styles from './Tiers.module.css';

export const tierRadioOptions = [
  {
    label: 'Two',
    value: '2',
  },
  {
    label: 'Three',
    value: '3',
  },
  {
    label: 'Four',
    value: '4',
  },
];

export const tiersProps = PropTypes.shape({
  label: PropTypes.string,
  minimumCutoff: PropTypes.number,
  rank: PropTypes.string,
});

export const defaultTiersProps = PropTypes.shape({
  2: PropTypes.arrayOf(tiersProps),
  3: PropTypes.arrayOf(tiersProps),
  4: PropTypes.arrayOf(tiersProps),
});

export const propTypes = {
  editable: PropTypes.bool.isRequired,
  isStandard: PropTypes.bool.isRequired,
  scoreName: PropTypes.string.isRequired,
  tierCollectionId: PropTypes.number.isRequired,
  tiers: PropTypes.arrayOf(tiersProps).isRequired,
  defaultTiers: defaultTiersProps,
};

const defaultProps = {
  defaultTiers: undefined,
};

function subheaderCopy(isStandard) {
  if (isStandard) {
    return 'These uneditable scoring tiers are configured by CreativeX and apply to the Creative Quality Score';
  }

  return 'These editable scoring tiers are configured by company admins and apply to all custom scores';
}

function tierLabelErrorMessage(label, labels) {
  if (label === '') {
    return 'Tier name cannot be blank.';
  } else if (label.length > 15) {
    return 'Tier name cannot exceed \n15 characters.';
  } else if (labels.filter((l) => l === label).length > 1) {
    return 'Tier name must be unique';
  }

  return null;
}

function tierValueErrorMessage(rank, score, min, max) {
  if (rank !== 'lowest' && score === 0) {
    return 'Score must be greater than 0';
  } else if (score < min) {
    return `Score must be greater than ${min - 1}`;
  } else if (score > max) {
    return `Score must be less than ${max}`;
  }

  return null;
}

export function formatTiers(tiers, labels) {
  return tiers.map((tier, idx) => {
    const {
      label,
      minimumCutoff,
      rank,
    } = tier;

    const isHighest = rank === 'highest';
    const isLowest = rank === 'lowest';
    const maximum = isHighest ? 100 : tiers[idx - 1].minimumCutoff;
    const minimum = isLowest ? 0 : tiers[idx + 1].minimumCutoff + 1;
    const labelError = tierLabelErrorMessage(label, labels);
    const scoreError = tierValueErrorMessage(rank, minimumCutoff, minimum, maximum);

    return {
      ...tier,
      labelError,
      maximum,
      minimum,
      scoreError,
    };
  });
}

function Tiers({
  defaultTiers,
  editable,
  isStandard,
  scoreName,
  tierCollectionId: id,
  tiers: initialTiers,
}) {
  const [editing, setEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [tierCount, setTierCount] = useState(initialTiers.length);
  const [tiers, setTiers] = useState(initialTiers);

  useEffect(() => {
    setIsChanged(() => {
      if (tiers.length !== initialTiers.length) return true;

      return initialTiers.some((tier, idx) => (
        (tier.label !== tiers[idx].label) || (tier.minimumCutoff !== tiers[idx].minimumCutoff)
      ));
    });
  }, [tiers]);

  const onTierCountChange = (count) => {
    setTierCount(count);

    if (count === initialTiers.length) {
      setTiers(initialTiers);
    } else {
      const sortedDefaultTiers = sortObjectArray(defaultTiers[count], 'minimumCutoff', false);
      setTiers(sortedDefaultTiers);
    }
  };

  const onTierChange = (rank, property, value) => {
    setTiers((prevState) => {
      const idx = prevState.findIndex((tier) => tier.rank === rank);

      return [
        ...prevState.slice(0, idx),
        {
          ...prevState[idx],
          [property]: value,
        },
        ...prevState.slice(idx + 1),
      ];
    });
  };

  const onCancelEditing = () => {
    setEditing(false);
    setTiers(initialTiers);
    setTierCount(initialTiers.length);
  };

  const labels = tiers.map(({ label }) => label);
  const formattedTiers = formatTiers(tiers, labels);

  const hasError = formattedTiers.some(({ labelError, scoreError }) => (labelError || scoreError));

  return (
    <>
      <Card>
        <h5>{ `${scoreName} tiers` }</h5>
        <div className="u-flexRow u-marginTop-8">
          <p className="t-body-1">{ subheaderCopy(isStandard) }</p>
          { isStandard && <div className={styles.trademark}>&trade;</div> }
        </div>
        <Divider margin />
        { editing && (
          <div className="u-flexRow u-marginBottom-16">
            <RadioGroup
              label="Number of Tiers"
              name="num_tiers"
              options={tierRadioOptions}
              value={tierCount.toString()}
              horizontal
              onChange={(e) => onTierCountChange(parseInt(e.target.value))}
            />
          </div>
        ) }
        <TierForm
          editing={editing}
          tiers={formattedTiers}
          onTierChange={onTierChange}
        />
        { editing && <TierRangeChart tiers={tiers} /> }
        <div className={classNames('buttonGroup', 'u-marginTop-16', 'u-justifyEnd')}>
          { editable && !editing && (
            <Button
              label="Edit"
              onClick={() => setEditing(true)}
            />
          ) }
          { editing && (
            <>
              <Button
                label="Cancel"
                variant="tertiary"
                onClick={onCancelEditing}
              />
              <Button
                disabled={hasError || !isChanged}
                label="Update"
                onClick={() => setShowReview(true)}
              />
            </>
          ) }
        </div>
      </Card>
      <TierConfirmation
        id={id}
        isOpen={showReview}
        newTiers={formattedTiers}
        oldTiers={formatTiers(initialTiers, initialTiers.map(({ label }) => label))}
        onClose={() => setShowReview(false)}
      />
    </>
  );
}

Tiers.propTypes = propTypes;
Tiers.defaultProps = defaultProps;

export default Tiers;
